@import "src/style/variables";

.comments_ac {
  //width: calc(100% + 65px);
  position: relative;
  //height: 400px;
  overflow: auto;
  margin-top: 31px;
  padding-right: 10px;
  width: max-content;
  line-height: normal;
  @include phone {
    margin-top: 20px;
    height: auto;
    width: 100%;
    padding-right: 0;
    overflow: initial;
  }
  &__comment {
    width: 527px;
    border-radius: 4px;
    position: relative;
    padding: 6px 22px 6px 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    border: 1px rgba(153, 206, 255, 0.25);
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(56, 159, 255, 0);
    background: linear-gradient(180deg, rgba(153, 206, 255, 0.29) 0%, rgba(47, 123, 194, 0) 100%), #0A4982;
    margin-top: 10px;
    word-break: break-all;
    @include phone {
      width: 100%;
    }
    img {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .text {
      position: relative;
      width: calc(100% - 52px);
      text-align: left;
      margin-left: 12px;
      padding-top: 7px;

      &__account {
        font-weight: 300;
        line-height: 14px;
        font-size: 12px;
        color: #99CEFF;
        margin-bottom: 2px;
      }

      &__text {
        font-weight: 300;
        line-height: 17px;
        font-size: 12px;
        color: #FFFFFF;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(to left, $tr 0%, $tr 35%, #235D91 36%, #235D91 65%, $tr 66%, $tr 100%);
  }

  &::-webkit-scrollbar-thumb {
    background: #99CEFF;
    border-radius: 2px;
  }
}
