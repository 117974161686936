@import "../../style/variables";

$gapTop:20px;
$gapLeft:36px;
$cellW:209px;

.scroll {
  overflow-x: hidden;
  overflow-y: auto;

  .scroll_cell {
    display: grid;
    grid-template-columns: repeat(4, $cellW);
    grid-gap: $gapTop $gapLeft;
    justify-content: center;
    padding-bottom: 20px;

    @for $i from 4 through 2 {
      @media only screen and (max-width: $i*$cellW + $i*$gapLeft) {
        @if $i == 2 {
          grid-template-columns: 280px;
        } @else  {
          grid-template-columns: repeat($i - 1, $cellW);
        }
      }
    }

  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(to left, $tr 0%, $tr 35%, #235D91 36%, #235D91 65%, $tr 66%, $tr 100%);
  }

  &::-webkit-scrollbar-thumb {
    background: #99CEFF;
    border-radius: 2px;
  }
}
