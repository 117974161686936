@import "src/style/variables";
.filter {
  flex: none;
  height: 45px;
  padding-bottom: 13px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
  @include phone {
    padding: 20px 0 30px 0;
  }
  .spacer {
    flex: 1 0 auto;
  }
}
