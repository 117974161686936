@import "./style/variables";
@import "src/style/reset";

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/Roboto-Light.ttf');
  font-weight: normal;
  font-style: normal
}
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal
}



* {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  color: white;
}

#root,
body,
html {
  height: 100%;
  background: #193f6a;
}

ul {
  list-style-type: none;
  margin: 0;
}

td, th, ul {
  padding: 0;
}

body{
  @include scroll
}

.home {
  position: relative;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  display: block;
  //overflow: hidden;
  background: #061A37;
  padding: 20px 20px 0 20px;
  @include phone {
    padding:  0 20px;
  }


  &__bg {
    background-image: url("assets/img/3ca87838f191d579285de1f587310310.jpg");
    background-size: cover;
    background-position: 0% 30%;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.2;
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 0;
  }

  &__content {

    margin: 0 auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 948px;
    @include phone {
      min-height: 100vh;
      display: flex;
    }

    &.home__content_wide {
      padding-right: 25px;

      .home__head {
        padding-right: 25px;
      }

      .filter {
        padding-right: 20px;
      }
    }

    &.home__content_top {
      justify-content: flex-start;
    }
  }

  &__head {
    height: 79px;
    width: 100%;
    line-height: 79px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    @include phone {
      flex-direction: column;
      height: max-content;
      justify-content: start;
      align-items: start;
    }
    &.noMain{
      //margin-top: 20px;
      @include phone {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        margin-top: 0px;
      }
    }
  }

  &__title {
    font-weight: normal;
    line-height: 29px;
    font-size: 25px;
    color: #FFFFFF;
    @include phone {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  &__logo {
    width: 159px;
    height: 34px;
    background-image: url(assets/img/logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 11px;
    @include phone {
      margin-top: 20px;
      order: -1;
    }
  }


  &__btn {
    height: 30px;
    line-height: 28px;
    border: 1px solid transparentize(#99CEFF, .3);
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    font-weight: 300;
    line-height: 10px;
    font-size: 10px;
    padding: 10px 15px 10px 40px;
    cursor: pointer;
    background-color: transparent;

    &:before {
      position: absolute;
      content: '';
      background: url(assets/img/back-btn.svg);
      width: 16px;
      height: 10px;
      top: 9px;
      left: 10px;
    }

    span {
      display: block;
      line-height: 12px;
      text-transform: uppercase;
      color: #99CEFF;
      vertical-align: middle;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 40px;
    flex: none;

    &:last-child {
      padding-bottom: 0px;
    }

    &.home__row_filter {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__scroll {
    flex: 1 0 auto;
    position: relative;
    height: calc(100vh - 150px);
    overflow: auto;
    padding-right: 20px;
    margin-right: -20px;
    @include phone {
      height: calc(100vh - 200px);
    }
  }

  &__panel {
    flex: none;
    width: 100%;
    transition: filter 150ms;
    height: calc(100vh - 130px);


    @include phone {
      width: auto;
      margin: 0 -20px;
      flex-grow: 1;
      height: auto;
    }


    &:last-child {
      //padding-bottom: 20px;
    }
  }
}


.noMain.home__head{
  .home__logo {
    margin-top: 0px;
  }

}

.preloder {
  position: absolute;

  &_c_60 {
    left: calc(50% - 30px);
    top: calc(50% - 45px);
  }
}

.blur {
  filter: blur(4px);
}

.ReactModal__Content {
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 150ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

.ReactModal__Overlay {
  background-color: rgba(26, 63, 103, 0.7) !important;
}
