@import "src/style/variables";

.select {
  width: 171px;
  height: 30px;
  @include phone {
    width: 100%;
  }
  &.select_types {
    .select__selected {
      border: aliceblue;
    }
  }


  &__selected {
    position: relative;
    width: 100%;
    height: 30px;
    background: #001C3A;
    border-radius: 20px;
    text-align: left;
    font-weight: 300;
    padding: 7px 13px;
    line-height: 14px;
    font-size: 12px;
    line-height: 14px;
    display: block;
    text-transform: uppercase;
    color: #99CEFF;
    vertical-align: middle;
    background-image: url(../../../assets/img/select-arrows.svg);
    background-repeat: no-repeat;
    background-position: 95% center;
    appearance: none;

    option {
      line-height: 14px;
      display: block;
      text-transform: uppercase;
      color: #99CEFF;
      vertical-align: middle;
    }
  }
}
