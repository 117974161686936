
// Color variables
$orange: #fa801d;
$blue: #00b3eb;
$white: #ffffff;
$black: #000000;
$grey: #f3f3f3;
$dark-grey: #DADADA;
$tr: #ff000000;

// Screen size variables
$desktop: 1280px;
$laptop: 1070px;
$tablet: 950px;
$phone: 950px;

@mixin iphoneX {
  @media only screen and (min-device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
    only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: $laptop) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: $phone) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}

@mixin hd {
  @media only screen and (max-width: 1919px) {
    @content;
  }
}

@mixin uhd {
  @media only screen and (min-width: 2200px) and (max-width: 3839px) {
    @content;
  }
}

@mixin scroll{
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #99CEFF;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(to left, $tr 0%, $tr 35%, #235D91 36%, #235D91 65%, $tr 66%, $tr 100%);
    width: 1px;
  }


}
