@import "src/style/variables";
.panel {
  padding: 33px 50px 30px 30px;
  position: relative;
  border-radius: 5px;
  //overflow: hidden;
  height: 100%;
  @include phone {
    padding: 20px;
    border-radius:0px ;
    height:auto;
    min-height: calc(100vh - 74px);
  }
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(153, 206, 255, 0.29) 0%, rgba(47, 123, 194, 0) 100%), #0A4982;
    opacity: 0.5;
    border: 1px solid rgba(153, 206, 255, 0.25);
    box-sizing: border-box;
    border-radius: 5px;
    @include phone {
      border-radius:0px ;
    }
  }

  &__timer {
    position: relative;
    line-height: 16px;
    padding: 0px 19px;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 13px;
      height: 13px;
      background: url("../../assets/img/timer-white.svg");
      @include phone {
        background: url("../../assets/img/timer-blue.svg");
      }
    }

    span {
      display: block;
      font-weight: normal;
      line-height: 16px;
      font-size: 14px;
      color: #99CEFF;
      vertical-align: middle;
      @include phone {
        font-size: 12px;
      }
    }
  }

  &__content {
    position: relative;
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    //height: 100%;
    height: calc(100% - 16px);
    @include phone {
      padding-top: 20px;
      flex-direction: column;
      justify-content: start;
    }
  }

  &__text {
    flex: none;
    width: calc(100% - 340px);
    position: relative;
    display: grid;
    grid-template-rows: repeat(4, minmax(0,min-content));
    @include phone {
      width: 100%;
    }
    hr{
      opacity: 0.3;
      border-style: inset;
      border-color: #99CEFF;
      display: none;
      border-width: 0.5px;
      @include phone {
        display: block;
        margin: 0 -20px;
      }
    }
  }

  &__status {
    flex: none;
    width: 248px;
    margin-left: 101px;

    @include phone {
      order: -1;
      margin-left: 0px;
      margin-bottom: 32px;
      width: 100%;
    }
    &_txt{
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      display: none;
      word-break: break-word;
      @include phone {
        display: inline;
        font-size: 20px;
      }
    }

    .header {
      margin-top: 10px;
      @include phone {
        margin-top: 20px;
        font-size: 12px;
      }
    }


  }

  &__header {
    font-weight: 400;
    line-height: 28px;
    font-size: 24px;
    color: #FFFFFF;
    display: block;
    @include phone {
      display: none;
    }
    span{
      word-break: break-word;
    }
  }

  &__links {
    padding-top: 13px;
    display: block;
    text-align: left;
    @include phone {
      padding-top: 20px;
    }
    .btn {
      display: inline-block;
      height: 30px;
      border: 1px solid rgba(153, 206, 255, 0.5);
      border-radius: 30px;
      padding: 6px 25px;
      position: relative;
      margin-right: 10px;
      line-height: 16px;
      font-size: 14px;
      cursor: pointer;
      transition: background 300ms;
      background: #2F7BC2;

      &:last-child {
        margin-right: 0;
      }

      &.comments {
        padding: 7px 15px 7px 33px;
        border: 1px solid #2F7BC2;

        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 14px;
          width: 12px;
          height: 13px;
          background: url("../../assets/img/comment-blue.svg");
        }
        &.active:before{
          background: url("../../assets/img/comment-white.svg");
        }

        span {
          font-weight: 300;
          //color: #FFFFFF;
          vertical-align: middle;
        }
      }

      &.addComments {
        padding: 7px 15px 7px 33px;
        border: 1px solid #2F7BC2;

        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 14px;
          width: 12px;
          height: 13px;
          background: url("../../assets/img/comment2-blue.svg");
        }
        &.active:before{
          background: url("../../assets/img/comment2-white.svg");
        }

        span {
          font-weight: 300;
          //color: #FFFFFF;
          vertical-align: middle;
        }
      }




      span {
        font-weight: 400;
        text-align: center;
        line-height: 16px;
        display: block;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        vertical-align: middle;
      }
    }

    .btn.active {
      background: transparent;

      span {
        color:  #99CEFF;
      ;
      }
    }
  }

  &__article {
    //padding-top: 30px;

    & > * {
      font-weight: 400;
      line-height: 16px;
      font-size: 14px;
      color: #FFFFFF;
      text-align: left;
      margin-bottom: 5px;
    }

    ul {
      li {
        padding-left: 20px;
        margin-bottom: 12px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #fff;
        }
      }
    }
  }

  &__stat {
    position: relative;
    min-height: 71px;
    margin-top: 14px;
    .header {
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 15px;

      span {
        line-height: 21px;
        font-size: 18px;
        color: #FFFFFF;
      }
    }

    .line.noVoited {
      opacity: 0.3;
    }

    .line {
      height: 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-right: 60px;

      & + .line {
        margin-top: 11px;

        .line__bar,
        .line__btn {
          background: #FB5E5B;
        }
      }

      &__bar {
        height: 30px;
        background: #39C948;
        border-radius: 4px;
        line-height: 30px;
        animation: line 0.6s;
        margin-right: 5px;
        min-width: min-content;
      }

      &__val {
        font-weight: 300;
        line-height: 12px;
        font-size: 10px;
        color: #FFFFFF;
        padding: 9px 11px;
        display: block;
        text-align: left;
      }

      &__btn {
        height: 30px;
        background: #39C948;
        border-radius: 30px;
        line-height: 16px;
        margin-right: -65px;
        border: none;
        padding: 0px;
        cursor: pointer;

        @include phone {
          margin-right: -80px;
        }

        span {
          display: block;
          font-weight: 400;
          line-height: 16px;
          font-size: 14px;
          text-align: center;
          color: #FFFFFF;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
          padding: 7px 21px;
        }
      }
      &__btn[disabled] {
        cursor: default;
        background: transparent !important;
      }

    }
  }

  &__price {
    padding-top: 20px;

    span {
      font-weight: 400;
      line-height: 16px;
      font-size: 14px;
      color: #FFFFFF;

      &.free {
        color: #39C948;
        padding-left: 7px;
      }
    }
  }

  &__account {
    padding-top: 14px;

    .header {
      span {
        font-weight: 400;
        line-height: 16px;
        font-size: 14px;
        color: #FFFFFF;

        &.icon {
          display: inline-block;
          margin-left: 7px;
          width: 12px;
          height: 12px;
          background: url("../../assets/img/question.svg");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

  }
}

@keyframes line {

  0% {
    width: 0%;
  }

}
