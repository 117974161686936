.vote-cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgba(153, 206, 255, 0.25);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(56, 159, 255, 0);
  padding: 12px 15px 12px 19px;
  overflow: hidden;
  transition: box-shadow .3s;
  cursor: pointer;

  &:nth-child(4n+4) {
    margin-right: 0px;
  }
  &__stat {
    //width: calc(100% - 72px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &:before {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    content: '';
    background: linear-gradient(180deg, rgba(153, 206, 255, 0.29) 0%, rgba(47, 123, 194, 0) 100%), #0A4982;
    opacity: 0.5;
    border-radius: 5px;
    z-index: 0;
    transition: opacity .3s;
  }

  &:hover {
    box-shadow: 0px 0px 10px rgba(56, 159, 255, 0.5);

    &:before {
      opacity: 1;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__timer {
    position: relative;
    padding-left: 19px;
    font-weight: 400;
    line-height: 16px;
    font-size: 14px;
    color: #99CEFF;

    &:before {
      position: absolute;
      content: '';
      width: 13px;
      height: 13px;
      top: 0;
      left: 0;
      background-image: url("../../assets/img/timer-blue.svg");
      background-position: center;
      background-repeat: no-repeat;
    }

    &.active {
      color: #ffffff;

      &:before {
        background-image: url("../../assets/img/timer-white.svg");
      }
    }
  }

  &__comments {
    position: relative;
    padding-right: 18px;
    text-align: right;
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    color: #99CEFF;

    &:before {
      position: absolute;
      content: '';
      width: 12px;
      height: 13px;
      top: 0;
      right: 0;
      opacity: .3;
      background-image: url("../../assets/img/comment-blue.svg");
      background-position: center;
      background-repeat: no-repeat;
    }

    &.active:before {
      opacity: 1;
    }
  }

  &__theme {
    position: relative;
    padding-top: 12px;

    &.img {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;

      & > span {
        display: none;
      }

      img,
      div {
        display: block;
      }
    }

    &.block {
      div span:nth-child(2) {
        color: #FB5E5B;
      }
    }

    & > span {
      font-weight: 400;
      line-height: 19px;
      font-size: 16px;
      color: #FFFFFF;
      word-break: break-word;
    }

    img {
      display: none;
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }

    div {
      display: none;
      //width: 123px;
      text-align: left;

      span {
        display: block;

        &:nth-child(1) {
          font-weight: 400;
          line-height: 19px;
          font-size: 16px;
          color: #FFFFFF;
          padding-bottom: 4px;
          margin-left: 8px;
        }

        &:nth-child(2) {
          font-weight: 300;
          line-height: 14px;
          font-size: 12px;
          color: #39C948;
        }
      }
    }
  }

  &__text {
    position: relative;
    padding-top: 12px;
    font-weight: 300;
    line-height: 14px;
    font-size: 12px;
    color: #99CEFF;

    span {
      max-height: 85px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      position: relative;
      color: #99CEFF;
      word-break: break-word;
    }
    span:after {
      //content:' ...';
      background: inherit;
      position: absolute;
      bottom: 0;
    }
  }

  &__footer {
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 10;
    margin-top: 10px;
  }

  &__btn {
    height: 30px;
    font-weight: 300;
    line-height: 28px;
    border-radius: 30px;
    border: 1px solid rgba(153, 206, 255, 0.5);
    letter-spacing: .5px;
    align-self: center;
    cursor: pointer;

    &.active {
      background: #2F7BC2;
      border: 1px solid #2F7BC2;

      span {
        color: #FFFFFF;
        padding: 6px 16px;
      }
    }

    span {
      display: block;
      line-height: 16px;
      font-size: 14px;
      text-align: center;
      padding: 6px 12px;

    }

    span.voted {
      opacity: 0.5;
      color: #99CEFF;
    }
  }

  &__stat {
    height: auto;
    .line.noVoited {
      opacity: 0.3;
    }
    .line {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;

      &__yea {
        padding-top: 9px;
        background: #39C948;
        display: block;
        border-radius: 4px;
        height: 10px;
        animation: line 1s;
      }

      &__nay {
        display: block;
        background: #FB5E5B;
        border-radius: 4px;
        height: 10px;
        animation: line 1s;
      }

      span {
        display: block;
        font-weight: 300;
        line-height: 12px;
        font-size: 10px;
        color: #FFFFFF;
        width: 60px;
        flex-shrink: 0;
        word-break: break-all;
      }
      &__txt{
        padding-left: 5px;
      }
    }
  }
}

.scroll {
  &.scroll_cell {
    .vote-cell {
      margin-bottom: 19px;
    }
  }
}

@keyframes line {

  0% {
    width: 0%;
  }

}
