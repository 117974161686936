@import "src/style/variables";

.panel__article {
    margin-top: 31px;
    padding-right: 55px;
    overflow: auto;
    line-height: normal;
    white-space: pre-wrap;
    word-break: break-word;

    @include scroll;
    @include phone {
        height: fit-content;
        margin-top: 20px;
        padding-right: 0;
        max-height: max-content;
        overflow: inherit;

    }
}

.panel__article.panel__article_multi {
    margin-top: 27px;
    position: relative;
    left: -9px;
    overflow: auto;
    @include phone {
        margin-top: 20px;
        right: 0;
        left: 0;
    }
}

.panel__article.panel__article_multi > p {
    margin-left: 9px;
    word-break: break-word;
    @include phone {
        margin-left: 0;
    }
}


.panel__article > * {
    font-weight: 400;
    line-height: 16px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: left;
    margin-top: 0;
    margin-bottom: 12px;
}

.panel__article > *:last-child {
    margin-bottom: 0;
}

.panel__article ul li {
    padding-left: 20px;
    margin-bottom: 12px;
    position: relative;
}

.panel__article ul li:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 6px;
    height: 6px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
}

.panel__article .options {
    margin-top: 13px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 5px;
}

.panel__article .options input {
    display: none;
}

.panel__article .options input:checked + label .options__text:before {
    background: url(../../assets/img/voting-check_active.svg);
}

.panel__article .options input:checked + label .options__text span {
    color: #fff;
}

.panel__article .options input:checked + label .options__num span {
    color: #fff;
}

.panel__article .options__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    /*cursor: pointer;*/
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 5px;

}

.panel__article .options__item:nth-child(2) {
    margin-top: 0;
}

.panel__article .options__text {
    position: relative;
    display: block;
    width: 100%;
    word-break: break-word;
    &:after{
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        right: -16px;
        bottom: 0;
        width: 1px;
        background: rgba(153, 206, 255, 0.3);
    }
}

.panel__article .options__text:before {
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    top: calc(50% - 13px);
    left: 9px;
    background: url(../../assets/img/voting-check.svg);
}

.panel__article .options__text span {
    line-height: 16px;
    font-size: 14px;
    color: #99CEFF;
    background: rgba(0, 0, 0, 0);
    background: rgba(0, 28, 58, 0.3);
    -webkit-border-radius: 20px;
    border-radius: 20px;
    padding: 11px 10px 12px 49px;
    display: block;
}

.panel__article .options__num {
    position: relative;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

//.panel__article .options__num:before {
//    content: '';
//    display: block;
//    position: absolute;
//    top: -17px;
//    left: 16px;
//    bottom: -11px;
//    width: 1px;
//    background: rgba(153, 206, 255, 0.3);
//}

.panel__article .options__num span {
    color: #99CEFF;
    display: block;
    //width: 11px;
    margin-left: 35px;
}

.panel__article .voting-button {
    display: block;
    text-align: left;
    margin-left: 9px;
    margin-top: -2px;
}

.panel__article .voting-button__btn {
    display: inline-block;
    background: #39C948;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    padding: 13px 90px 11px 89px;
    cursor: pointer;
    margin-bottom: 2px;
}

.panel__article .voting-button__btn.disabled {
    opacity: .3;
    cursor: auto;
}

.panel__article .voting-button__btn span {
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}
